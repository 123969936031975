@import 'palette.module';

$darkBackground1: $color2;
$lightBackground1: #eef5f4;
$disabledBackground: $color1-20;
$dimBackground: $lightGrey;

$lineColor: #3a4f5c33;

$text: $primary;
$text2: $color2;
$textLink: $blue;
$textError: #dd3333;

$icon: '#fb8c00';

:export {
  darkBackground1: $darkBackground1;
  lightBackground1: $lightBackground1;
  disabledBackground: $disabledBackground;
  dimBackground: $dimBackground;
  lineColor: $lineColor;
  text: $text;
  text2: $text2;
  textLink: $textLink;
  textError: $textError;
  icon: $icon;
}
